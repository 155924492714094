<template>
  <div class="home-container">
    <!-- <img class="logo-bigger" src="../assets/logo.svg" alt="" /> -->
    <p
      :class="{ 'error-text': !success }"
      style="margin-top: 30px; font-weight: 700"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import auth from "../api/authService";
import bus from "../plugins/bus";

export default {
  data() {
    return {
      message: "",
      success: true,
    };
  },
  async created() {
    await this.activateAccount();
  },
  methods: {
    async activateAccount() {
      bus.$emit("processing");
      try {
        const message = await auth.activateAccount(
          this.$route.params.activationCode
        );
        this.message = message;
        bus.$emit("processing", false);
      } catch (e) {
        if (e.response && e.response.data && e.response.data.message) {
          this.success = false;
          this.message = e.response.data.message;
          bus.$emit("processing", false);
        }
      }
    },
  },
};
</script>
